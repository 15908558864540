import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export const ICON_SHAPES = [
  'all-floor',
  'chevron-up',
  'double-chevron-up',
  'loading-area',
  'pin',
  'banquet',
  'classroom',
  'facebook',
  'location-lg',
  'plane',
  'booth',
  'clock',
  'fax',
  'location',
  'reception',
  'briefcase',
  'close-sm',
  'food-lg',
  'mail',
  'surrounding',
  'bts',
  'close',
  'food-xl',
  'map-lg',
  'theatre',
  'calendar',
  'construction',
  'food',
  'map',
  'topic-gold',
  'car',
  'contact',
  'gallery-lg',
  'mrt',
  'topic-grey',
  'chevron-down',
  'documents',
  'gallery',
  'news',
  'transport',
  'chevron-left',
  'double-chevron-down',
  'hamburger',
  'twitter',
  'chevron-right-thin',
  'double-chevron-left',
  'information',
  'pdf',
  'website',
  'chevron-right',
  'double-chevron-right',
  'link',
  'phone',
  'youtube',
  'continue',
  'preserve',
  'build',
  'square',
  'megaphone',
  'arrow-up',
  'arrow-down',
  'bus',
  'parking',
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6',
  'number-7',
  'number-8',
  'instagram',
  'marker',
  'weixin'
];
export const ICON_COLORS = ['gold', 'blue', 'ivory', 'white', 'black'];

export type IconShape = typeof ICON_SHAPES[number];
export type IconColor = typeof ICON_COLORS[number];

export interface IconProps {
  name: typeof ICON_SHAPES[number];
  color?: typeof ICON_COLORS[number];
  modifiers?: ModifierProp<'text-prefix'>;
}

export const Icon: React.FC<IconProps> = ({ name, color = 'gold', modifiers }) => (
  <i className={mapModifiers('a-icon', modifiers, name, color)} />
);
