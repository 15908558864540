import React from 'react';
import { mapModifiers } from 'libs/component';

export interface WholeProps {
  children?: React.ReactNode;
  className?: string;
}

export const Whole: React.FC<WholeProps> = ({ children, className: additionalClassName = '' }) => {
  const className = [mapModifiers('o-whole'), additionalClassName].join(' ').trim();
  return <div className={className}>{children}</div>;
};
