import React, { useCallback } from 'react';
import { makeSureSmoothScrollPolyfilled } from 'libs/polyfills';
// import { ENV } from 'libs/env';
import { Icon } from 'components/atoms/icon';

makeSureSmoothScrollPolyfilled();

interface BackToTopButtonProps {
  scrollElement?: HTMLElement | string;
  onClick?: () => void;
}

export const BackToTopButton: React.FC<BackToTopButtonProps> = ({
  scrollElement: defaultScrollElement,
  onClick: onButtonClick,
}) => {
  const onClick = useCallback(() => {
    const scrollElement = defaultScrollElement ? defaultScrollElement : window;
    const element = typeof scrollElement === 'string' ? document.querySelector(scrollElement) : scrollElement;
    element?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (onButtonClick) {
      onButtonClick();
    }
  }, [defaultScrollElement, onButtonClick]);

  return (
    <button aria-label="Back to top" type="button" className="a-back-to-top-button" onClick={onClick}>
      <Icon name="arrow-up" />
    </button>
  );
};
