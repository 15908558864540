import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import SmoothScrollPolyfill from 'smoothscroll-polyfill';
import 'intersection-observer';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export function makeSureResizeObserverPolyfilled() {
  if (typeof window === 'undefined' || 'ResizeObserver' in window) {
    return;
  }

  window.ResizeObserver = ResizeObserverPolyfill;
}

export function makeSureSmoothScrollPolyfilled() {
  if (typeof window === 'undefined') {
    return;
  }

  SmoothScrollPolyfill.polyfill();
}
