import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { TextField } from 'components/atoms/text-field';
import { Button } from 'components/atoms/button';
import { EMAIL_REGEXP } from 'libs/constants';
import { useTranslation } from 'react-i18next';

export interface SubscribeInputProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  error?: any;
  modifiers?: ModifierProp<'invalid'>;
  defaultValue?: string;
}

export const SubscribeInput: React.FC<SubscribeInputProps> = ({ error, modifiers, defaultValue }) => {
  const { t } = useTranslation();

  return (
    <div className={mapModifiers('a-subscribe-input', modifiers)}>
      <div className="a-subscribe-input__text-field">
        <TextField
          type="email"
          placeholder={t('footer.subscribe.email-placeholder')}
          label={t('footer.subscribe.email')}
          modifiers={modifiers}
          error={error}
          defaultValue={defaultValue}
          formKey="email"
          validationRules={{
            required: t('form-error.require'),
            pattern: {
              value: EMAIL_REGEXP,
              message: t('form-error.email'),
            },
          }}
        />
      </div>
      <div className="a-subscribe-input__button">
        <Button type="submit" modifiers="primary">
          {t('footer.subscribe.register')}
        </Button>
      </div>
    </div>
  );
};
