import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface SectionProps {
  children?: React.ReactNode;
  modifiers?: ModifierProp<'full-width'>;
  className?: string;
}

export const Section: React.FC<SectionProps> = ({ children, modifiers, className: additionalClassName = '' }) => {
  const className = [mapModifiers('m-section', modifiers), additionalClassName].join(' ').trim();
  return <section className={className}>{children}</section>;
};
