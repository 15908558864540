/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Ref, RefObject, useCallback, useMemo, useState } from 'react';
import Link from 'next/link';
import Select, { components } from 'react-select';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Icon } from 'components/atoms/icon';
import { Button } from 'components/atoms/button';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const logoSrc = '/images/qsncc-logo.svg';
const logoSmSrc = '/images/qsncc-logo-sm.svg';
const logoWhiteSrc = '/images/qsncc-logo-wh.svg';

/**
 * Header Social Nav Item
 */
export interface GlobalHeaderSocialItemProps {
  type: ModifierProp<'facebook' | 'twitter' | 'youtube' | 'instagram' | 'weixin'>;
  link: string;
}

export const GlobalHeaderSocialItem: React.FC<GlobalHeaderSocialItemProps> = ({ type, link }) => (
  <li className={mapModifiers('o-global-header__social-item')}>
    <Button icon={<Icon name={type as string} color="gold" />} href={link} target="_blank" />
  </li>
);

/**
 * Header Nav Item
 */
export interface GlobalHeaderItemProps {
  component: React.ReactElement;
  active?: boolean;
}

export const GlobalHeaderItem: React.FC<GlobalHeaderItemProps> = ({ component, active = false }) => {
  return useMemo(() => {
    const nav = React.cloneElement(component, { ...component.props, className: 'o-global-header__nav' });
    return <li className={mapModifiers('o-global-header__item', active && 'active')}>{nav}</li>;
  }, [component, active]);
};

export interface LanguageSelectorOptionProps {
  value: string;
  label: string;
}

/**
 * Language Selector Component
 */
const LanguageSelectorDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="chevron-down" color="gold" />
    </components.DropdownIndicator>
  );
};

/**
 * Main Global Header
 */

export interface GlobalHeaderProps {
  ref?: RefObject<any>;
  className?: string;
  modifiers?: ModifierProp<'transparent'>;
  color?: ModifierProp<'dark'>;
  defaultLanguage: string;
  languageOptions: LanguageSelectorOptionProps[];
  onLanguageChange?: (language: string) => undefined;
  children?: React.ReactNode;
  socials?: React.ReactNode;
}

/* export const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  className: additionalClassName = '',
  modifiers,
  color,
  // defaultLanguage = 'en',
  // languageOptions,
  // onLanguageChange,
  children,
  socials,
}) => { */
// eslint-disable-next-line react/display-name
export const GlobalHeader = React.forwardRef<any, GlobalHeaderProps>(
  (
    {
      className: additionalClassName = '',
      modifiers,
      color,
      defaultLanguage = 'cn',
      languageOptions,
      onLanguageChange,
      socials,
    },
    ref: Ref<any>
  ) => {
    const { i18n } = useTranslation();
    const componentClassName = mapModifiers('o-global-header', modifiers, color, socials ? 'socials' : undefined);
    const className = `${componentClassName} ${additionalClassName}`.trim();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
    const [currentLanguageOption, setCurrentLanguageOption] = useState(() => {
      if (defaultLanguage) {
        return languageOptions.filter(x => x.value === defaultLanguage).pop();
      } else {
        return languageOptions[0];
      }
    });
    const [isMainNavShown, setIsMainNavShown] = useState(false);


    useEffect(() => {
      setCurrentLanguage(defaultLanguage);
      setCurrentLanguageOption(languageOptions.filter(x => x.value === defaultLanguage).pop());
    }, [defaultLanguage, languageOptions]);

    const handleLanguageChange = useCallback(
      option => {
        setCurrentLanguage(option.value);
        setCurrentLanguageOption(option);
        if (i18n) {
          i18n.changeLanguage(option.value);
          const cwcCookieBanner = (window as any).cwcCookieBanner;
          if (cwcCookieBanner) {
            cwcCookieBanner.setLang(option.value);
          }
        }
        if (onLanguageChange) {
          onLanguageChange(option.link);
          // console.log(option)
        }
      },
      [i18n, onLanguageChange]
    );

    const handleToggleMainNav = useCallback(() => {
      setIsMainNavShown(!isMainNavShown);
    }, [isMainNavShown]);

    return (
      <header ref={ref} id="header" className={className}>
        <div className="o-global-header__container">
          <div className="o-global-header__main">
            <div className={mapModifiers('o-global-header__nav-toggle', isMainNavShown && 'expand')}>
              <button
                aria-label="Toggle Navigation"
                type="button"
                className="o-global-header__nav-toggle-button"
                onClick={handleToggleMainNav}
              >
                {!isMainNavShown && <Icon name="hamburger" />}
                {isMainNavShown && <Icon name="close" />}
              </button>
            </div>
            <div className="o-global-header__brand">
              <Link href={`/${i18n.language}`} locale={false}>
                <a>
                  <img
                    className="o-global-header__brand-logo"
                    src={color === 'dark' ? logoWhiteSrc : logoSrc}
                    alt="QSNCC logo"
                    width="150"
                    height="44"
                  />
                  <img
                    className="o-global-header__brand-logo-sm lazyload"
                    data-src={logoSmSrc}
                    alt="QSNCC logo"
                    width="82"
                    height="24"
                  />
                </a>
              </Link>
            </div>
          </div>
          <div className={mapModifiers('o-global-header__side', isMainNavShown && 'expand')}>
            <nav id="main-nav" className="o-global-header__navigation" role="navigation" aria-label="Main">
              <ul className="o-global-header__navigation-inner">
                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="/">首页</a>
                    <div className="dropdown-content">
                      <a href="/cn/glance">概览</a>
                      <a href="/cn/privacy-policy">可持续性与标准</a>
                    </div>
                  </div>
                </li>


                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="#">活动规划</a>
                    <div className="dropdown-content">
                      <a href="/cn/venue-room"  rel="noopener noreferrer">场地空间</a>
                      <a href="/cn/service"  rel="noopener noreferrer">服务</a>
                      <a href="/cn/virtual" rel="noopener noreferrer">虚拟导览</a>
                    </div>
                  </div>
                </li>

                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="#">访客导览</a>
                    <div className="dropdown-content">
                      <a href="/cn/venue-info"  rel="noopener noreferrer">场地信息</a>
                      <a href="https://qsncc.com/en/visit/food-and-retail" target="_blank" rel="noopener noreferrer">美食与购物</a>
                      <a href="https://www.qsncc.com/en/visit/experience" target="_blank" rel="noopener noreferrer">配套设施</a>
                      <a href="/cn/bus-route"  rel="noopener noreferrer">乘车路线</a>
                    </div>
                  </div>
                </li>


                {/* <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="/cn//hotel-surrounding">酒店与周边环境</a>
                    <div className="dropdown-content">
                      <a href="/cn/venue-information">场馆信息</a>
                      <a href="">服务</a>
                      <a href="https://qsncc.com/en/visit/food-and-retail" target="_blank" rel="noopener noreferrer">美食与购物</a>
                      <a href="#" target="_blank" rel="noopener noreferrer">乘车路线</a>
                      <a className="dropbtn" href="https://www.qsncc.com/en/visit/experience">酒店与周边环境</a>
                    </div>
                  </div>
                </li>
                {/* <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="https://www.qsncc.com/en/visit/experience">酒店与周边环境</a>
                    
                  </div>
                </li> */}


                <li className="o-global-header__item"><div className="dropdown"><a className="dropbtn" href="#">正在发生</a>
                  <div className="dropdown-content">
                    <a href="https://www.qsncc.com/en/whats-on/event-calendar" target="_blank" rel="noopener noreferrer">展会一览</a>
                    <a href="https://www.qsncc.com/en/whats-on/happening" target="_blank" rel="noopener noreferrer">相关活动</a>
                  </div></div>
                </li>

                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="/cn/media/news">媒体</a></div>
                </li>
                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn"  target=" _blank"  href="https://www.qsncc.com/en/media/gallery">图片库</a></div>
                </li>
                <li className="o-global-header__item">
                  <div className="dropdown"><a className="dropbtn" href="/cn/contact-us">联系我们</a></div>
                </li>

              </ul>
              {/* <ul className="o-global-header__navigation-inner">{children}</ul> */}

            </nav>
            <div className="o-global-header__additional">
              {socials && <ul className="o-global-header__social-networks">{socials}</ul>}

              <div className="o-global-header__language-selector-container">
                <Select
                  id="language-selector"
                  instanceId="language-selector"
                  className={mapModifiers('o-global-header__language-selector', currentLanguage)}
                  classNamePrefix="o-global-header__language-selector"
                  value={currentLanguageOption}
                  onChange={handleLanguageChange}
                  options={languageOptions}
                  filterOptions={(options: any) => options}
                  components={{
                    DropdownIndicator: LanguageSelectorDropdownIndicator,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </header >
    );
  }
);
