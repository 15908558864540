import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, useEffect, useRef } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { useLinkSpy } from 'utils/link-spy';
import { makeSureInjectDataIsTouchDeviceAttributeToBody } from 'libs/layout';
import { IconProps } from 'components/atoms/icon';

type InheritedProps = Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'onClick'> &
  Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick' | 'disabled'>;

export interface ButtonProps extends InheritedProps {
  modifiers?: ModifierProp<'primary'>;
  size?: ModifierProp<'normal' | 'large' | 'small'>;
  children?: React.ReactNode;
  icon?: React.ReactElement<IconProps>;
  id?: string;
  className?: string;
  useNativeHashLink?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  modifiers,
  size,
  children,
  icon,
  href,
  target,
  type,
  onClick,
  disabled,
  className: additionalClassName = '',
  id,
  useNativeHashLink,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { dataProps } = useLinkSpy({ ref: linkRef });

  function createRipple(event: React.MouseEvent) {
    const button: HTMLElement = event.currentTarget as HTMLElement;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('a-button__ripple');

    const ripple = button.getElementsByClassName('a-button__ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  function onMouseDown(event: React.MouseEvent) {
    if (!disabled) {
      createRipple(event);
    }
  }

  useEffect(() => {
    makeSureInjectDataIsTouchDeviceAttributeToBody();
  }, []);

  if (href) {
    const componentClassName = mapModifiers(
      'a-button',
      modifiers,
      size,
      disabled && 'disabled',
      icon && !children ? 'icon' : undefined
    );
    const className = `${componentClassName} ${additionalClassName}`.trim();

    if (disabled) {
      return (
        <div className={className} id={id} onMouseDown={onMouseDown} {...(!useNativeHashLink ? dataProps : {})}>
          {icon && <div className="a-button__icon">{icon}</div>}
          <div className="a-button__label">{children}</div>
        </div>
      );
    } else {
      return (
        <a
          ref={!useNativeHashLink ? linkRef : undefined}
          href={href}
          target={target}
          className={className}
          id={id}
          onClick={onClick}
          rel={target === '_blank' ? 'noreferrer' : undefined}
          onMouseDown={onMouseDown}
          aria-label="button"
          {...(!useNativeHashLink ? dataProps : {})}
        >
          {icon && <div className="a-button__icon">{icon}</div>}
          <div className="a-button__label">{children}</div>
        </a>
      );
    }
  } else {
    const componentClassName = mapModifiers('a-button', modifiers, size, icon && !children ? 'icon' : undefined);
    const className = `${componentClassName} ${additionalClassName}`.trim();

    return (
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={createRipple}
        className={className}
        id={id}
      >
        {icon && <div className="a-button__icon">{icon}</div>}
        <div className="a-button__label">{children}</div>
      </button>
    );
  }
};
