import React, { useEffect } from 'react';
import { mapModifiers } from 'libs/component';
import { ScrollFreezer } from 'libs/scroll-freezer';
import { Icon } from 'components/atoms/icon';

export interface ModalProps {
  children?: React.ReactNode;
  opened?: boolean;
  onCloseRequested?: () => void;
  id?: string;
  className?: string;
  noCloseButton?: boolean;
  noHeader?: boolean;
  noBackground?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  opened,
  onCloseRequested,
  id,
  className: additionalClassName = '',
  noCloseButton,
  noHeader,
  noBackground,
}) => {
  const componentClassName = mapModifiers('o-modal', opened && 'opened', noBackground && 'no-background');
  const className = `${componentClassName} ${additionalClassName}`.trim();

  useEffect(() => {
    opened ? ScrollFreezer.freeze() : ScrollFreezer.unfreeze();

    return () => (opened ? ScrollFreezer.unfreeze() : undefined);
  }, [opened]);

  return (
    <div className={className} id={id}>
      <div className="o-modal__window">
        {!noHeader && (
          <header className="o-modal__header">
            {!noCloseButton && (
              <button className="o-modal__close-button" type="button" onClick={onCloseRequested}>
                <Icon name="close" />
              </button>
            )}
          </header>
        )}
        <main className="o-modal__body">{children}</main>
      </div>
    </div>
  );
};
