import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Button } from 'components/atoms/button';
import { Icon } from 'components/atoms/icon';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const nccLogo = '/images/ncc-lockup.svg';

export interface GlobalFooterProps {
  socials?: React.ReactNode;
}

/**
 * Footer Social Nav Item
 */
export interface GlobalFooterSocialItemProps {
  type: ModifierProp<'facebook' | 'twitter' | 'youtube' | 'instagram' | 'weixin'>;
  link: string;
}

export const GlobalFooterSocialItem: React.FC<GlobalFooterSocialItemProps> = ({ type, link }) => (
  <li className={mapModifiers('o-global-footer__social-item')}>
    <Button icon={<Icon name={type as string} color="" />} href={link} target="_blank" />
  </li>
);

export const GlobalFooter: React.FC<GlobalFooterProps> = ({ socials }) => {
  const { t,  } = useTranslation();
  // const { t, i18n } = useTranslation();
  // const now = new Date();
  // const currentYear = now.getFullYear();

  return (
    <footer className={mapModifiers('o-global-footer')}>
      <div className="o-global-footer__container">

        <div className="o-global-footer__main">
         <div className='address'>诗丽吉王后国家会议中⼼
         </div>
         <div className='addr2'>
         60 Queen Sirikit National Convention Center, Ratchadaphisek Road,<br/> Khlong Toei Sub-district,
Khlong Toei District, Bangkok 10110, Thailand
         </div>
 <div className='logo'>
          <div className="o-global-footer__logo">
            <img data-src={nccLogo} alt="NCC Logo" className="lazyload" width="53" height="48" />
          </div>
          <div className="o-global-footer__legal">
            <div className="o-global-footer__copyright">
              <div className="o-global-footer__copyright-item">NCC 管理与发展有限公司<br/>版权所有 &copy; 2024。保留所有权利。</div>
              {/* <div className="o-global-footer__copyright-separator">|</div> */}
              <div className="o-global-footer__copyright-item o-global-footer__copyright-item--copy">
                {/* Copyright &copy; {currentYear}. All rights reserved. */}
                <div className="o-global-footer__legal-links-sp">
                
                  {/* <div className="o-global-footer__legal-separator">|</div> */}
                  <div className="o-global-footer__legal-link">
                     <Link href="https://beian.miit.gov.cn">
                      <a>{t('footer.privacy')}</a>
                    </Link>
                  </div>
                  {/*
                  <div className="o-global-footer__legal-separator">|</div>
                  <div className="o-global-footer__legal-link"><a href="#">Terms &amp; Conditions</a></div>
                  */}
                </div>
              </div>
            </div>
            <div className="o-global-footer__legal-links">
              <div className="o-global-footer__legal-link">
                <Link href="https://beian.miit.gov.cn">
                  <a>{t('footer.privacy')}</a>
                </Link>
              </div>
              {/*
              <div className="o-global-footer__legal-separator">|</div>
              <div className="o-global-footer__legal-link"><a href="#">Terms &amp; Conditions</a></div>
              */}
            </div>
          </div>
          </div>
        </div>
        {socials && (
          <div className="o-global-footer__social-networks">
            <div className="o-global-footer__social-networks-label">关注我们</div>
            <ul className="o-global-footer__social-networks-list">{socials}</ul>
          </div>
        )}
      </div>
    </footer>
  );
};
