/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from 'components/atoms/text';
import { SubscribeInput } from 'components/atoms/subscribe-input';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import loadingLottieFile from 'lotties/loader.json';

export interface SubscribeBoxProps {
  modifiers?: ModifierProp<'invalid'>;
  onSubmit?: (data: any) => void;
  isSubmitting?: boolean;
}

export const SubscribeBox: React.FC<SubscribeBoxProps> = ({ modifiers, onSubmit, isSubmitting = false }) => {
  const { t } = useTranslation();

  const methods = useForm();
  const {
    formState: { errors },
  } = methods;

  const hasError = Object.keys(errors).length > 0;

  return (
    <div className={mapModifiers('m-subscribe-box', modifiers)}>
      <h1 className="m-subscribe-box__title">{t('footer.subscribe.title')}</h1>
      <div className="m-subscribe-box__subtitle">
        <Text align="center">
          <span
            dangerouslySetInnerHTML={{
              __html: t('footer.subscribe.description'),
            }}
          />
        </Text>
      </div>
      <div className="m-subscribe-box__form">
        {isSubmitting && (
          <div className="m-subscribe-box__form-loading">
            <div className="m-subscribe-box__form-loading-icon">
              <Lottie animationData={loadingLottieFile} />
            </div>
          </div>
        )}
        {!isSubmitting && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit as any)} noValidate={true}>
              <SubscribeInput error={errors} modifiers={hasError ? 'invalid' : undefined} />
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
