import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface TextProps {
  children?: React.ReactNode;
  modifiers?: ModifierProp<'exceprt' | 'subtitle'>;
  align?: ModifierProp<'left' | 'center' | 'right'>;
}

export const Text: React.FC<TextProps> = ({ children, modifiers, align }) => (
  <p className={mapModifiers('a-text', modifiers, align ? `align-${align}` : undefined)}>{children}</p>
);
