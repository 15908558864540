/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_ERROR_MESSAGES } from 'libs/constants';

type InheritedProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>;

export interface TextFieldProps extends InheritedProps {
  label?: string;
  autoHideLabel?: boolean;
  error?: any;
  modifiers?: ModifierProp<'invalid' | 'alphanumeric'>;
  formKey?: string;
  validationRules?: any;
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  autoHideLabel = true,
  modifiers,
  error,
  formKey = '',
  validationRules,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const labelRef = useRef<HTMLLabelElement>(null);
  const [isBlank, setIsBlank] = useState<boolean>(false);
  const formContext = useFormContext();
  const register = formContext ? formContext.register : () => undefined;

  let errorMessage = '';
  if (typeof error === 'object') {
    // find key
    Object.keys(error).forEach(key => {
      if (formKey === key) {
        const { type, message } = error[key];
        if (message !== '') {
          errorMessage = message;
        } else {
          errorMessage = DEFAULT_ERROR_MESSAGES[type as string] || '';
        }
      }
    });
  }

  const handleChange = useCallback(() => {
    if (autoHideLabel) {
      const val = inputRef?.current?.value || '';
      setIsBlank(val === '');
    } else {
      setIsBlank(false);
    }
  }, [autoHideLabel]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <div className={mapModifiers('a-text-field', modifiers)}>
      {label && <label className={mapModifiers('a-text-field__label', isBlank && 'blank')}>{label}</label>}
      <input
        {...register(formKey, validationRules)}
        {...props}
        className="a-text-field__input"
        ref={inputRef}
        onChange={handleChange}
      />
      {error && <div className="a-text-field__error">{errorMessage}</div>}
    </div>
  );
};
